import React from 'react';

const Privacy = () => {

  return (
    <div>
      <h4>Zhromažďovanie informácií o používateľoch, ochrana osobných údajov</h4>
      <p>Pri spracúvaní osobných údajov dodržiavame všetky predpisy, ktoré sa ochrany a spracovania osobných údajov dotýkajú - Nariadenie Európskeho parlamentu a Rady (EÚ) 2016/679 z 27. apríla 2016 o ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom pohybe takýchto údajov, ktorým sa zrušuje smernica 95/46/ES (ďalej len “nariadenie") a zároveň zákon č. 18/2018 Z. z. o ochrane osobných údajov a o zmene a doplnení niektorých zákonov (ďalej len “zákon”).</p>
      <h4>Na tejto stránke nájdete nasledujúce informácie</h4>
      <ul>
        <li>Zoznam údajov, ktoré získavame</li>
        <li>Spôsob, ako údaje využívame</li>
        <li>S kým údaje zdieľame</li>
        <li>Vaše možnosti kontroly</li>
        <li>Vaše práva</li>
        <li>Ochrana Vašich údajov</li>
      </ul>
      <h4>Zoznam údajov, ktoré získavame</h4>
      <p>V prípade nákupu v Službe vstup.me požadujeme technické údaje, ktoré sú potrebné na vybavenie Objednávky. Povinným údajom je e-mailová adresa. Je to jediný osobný údaj, ktorý pre spracovanie vašej Objednávky potrebujeme.</p>
      <p>V prípade, že ste sa ako Používateľ registrovali do Služby vstup.me ako promotér, umelec, festival, klub alebo médium (ďalej len “služba”), pýtame si od Vás údaje potrebné pre vytvorenie PRO profilu a to v rozsahu: emailová adresa, používateľské meno, fotografia, fakturačné údaje.</p>
      <h4>Spôsob, ako údaje využívame</h4>
      <p>Na základe údajov, ktoré od vás získavame, Vám vieme poskytovať Službu vstup.me - podľa Podmienok používania.</p>
      <p>V prípade nákupu vstupenky bez registrácie ide o uskutočnenie nákupu bez potreby vytvoriť profil Používateľa. Na spracovanie Vašej objednávky potrebujeme Vašu správnu emailovú adresu.</p>
      <p>V prípade používania registrovaného profilu promotéra, umelca, festivalu, klubu alebo média ide o uskutočnenie nákupu po prihlásení sa do profilu Používateľa, odporúčanie podujatí na základe Vásho používania služby vstup.me, hlasovanie vo verejných anketách vstup.me, predaj vstupeniek, pridanie podujatia do katalógu podujatí vstup.me. Uvedené kontaktné údaje využívame na komunikáciu pri vybavovaní Objednávok, pri reklamáciách a pri poskytovaní Služby. Využívame ich aj pre fakturačné a účtovné účely.</p>
      <p>Údaje, ktoré zbierame počas Vášho používania Služby, využívame na odporúčanie relevantných podujatí, produktov, iných profilov. Tieto údaje využívame používame pre reklamné, marketingové a analytické účely za účelom zlepšenia kvality našej Služby.</p>
      <p>Údaje využívame aj v konaniach pred štátnymi a kontrolnými orgánmi a pri vymáhaní nárokov, ktoré nám vznikli.</p>
      <h4>S kým údaje zdieľame</h4>
      <p>Amazon Web Services - dátové centrum pre Službu vstup.me - poskytovateľ hostingu a virtuálneho servera. </p>
      <h4>Vaše možnosti kontroly a Vaše práva</h4>
      <p>Súhlas so spracovaním osobných údajov je možné odvolať bezplatne a kedykoľvek a to nasledovnými spôsobmi</p>
      <p>formou e-mailu (obsahujúceho odvolanie súhlasu so spracúvaním osobných údajov) doručeného na e-mailovú adresu info@vstup.me</p>
      <p>Pokiaľ na spracúvanie vašich osobných údajov neexistuje právny základ, začneme ihneď s procesom vymazania (zabudnutia) vašich osobných údajov, doba spracovania tejto požiadavky je tak rýchla, ako to dovoľujú technické podmienky.</p>
      <p>Zaručujeme Vám všetky práva vyplývajúce z platnej legislatívy.</p>
      <h4>Ochrana Vašich údajov</h4>
      <p>Zaväzujeme sa prijať primerané opatrenia na zabezpečenie ochrany vašich údajov (vrátane osobných údajov) pred neoprávnenými zásahmi tretích osôb. Vaše osobné údaje chránime v súlade s požiadavkami platnej legislatívy. Vaše osobné údaje neprenášame aktívne do tretích krajín mimo Európskej únie.</p>
      <h4>Kontakt</h4>
      <p>Služba vstup.me je zároveň aj spracovateľom vašich osobných údajov. V prípade nejasností, otázok alebo námietok voči spôsobu spracovania vašich osobných údajov nás neváhajte kontaktovať.</p>
      <p>Gaskor s.r.o.</p>
      <p>Sídlo: Gaštanová 92, 076 15, Hrčeľ</p>
      <p>IČO 50152165 Spoločnosť zapísaná v obchodnom registri Mestského súdu Košice. Vložka č. 38470/V</p>
    </div>
  );
}

export default Privacy;