import React, { useEffect, useState } from 'react';
import {
  CloseOutlined,
  DownloadOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import {
  message,
  Input,
  Button,
  Space,
} from "antd";
import Cookies from 'js-cookie'

import { ConfigProps } from '../Config/ConfigModel';
import { getConfig } from '../Config/config';

import './Admin.css';

const unableToChangeTicketStateMessage = 'Nepodarilo upraviť stav lístka. Skúste prosím znovu. ';
const incorrectKeyMessage = 'Heslo nie je správne.';
const messageTimeout = 15;

const cookieApiAdminKey = 'vstup-me-api-admin-key';
const cookieExpirationInDays = 5;


const Admin = () => {
  const [config, setConfig] = useState<ConfigProps>();
  const [xApiAdminKey, setXApiAdminKey] = useState<string>();
  const [inputValue, setInputValue] = useState<string>();
  const [eventId, setEventId] = useState<string>();
  const [loading, setLoading] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();



  const storeXApiAdminKey = (key: string) => {
    Cookies.set(cookieApiAdminKey, key, { expires: cookieExpirationInDays });
    setXApiAdminKey(key);
  }

  const removeCookie = () => {
    Cookies.remove(cookieApiAdminKey);
    setXApiAdminKey(null);
  }

  const downloadInvoices = () => {
    if (config && config.baseUrl && eventId && xApiAdminKey) {
      setLoading(true);
      fetch(`${config.baseUrl}/api/admin?type=generate-invoices&eventId=${eventId}`,
        {
          method: 'GET',
          headers: {
            'x-api-admin-key': xApiAdminKey,
          },
        }
      )
        .then((res) => {
          if (res.status === 401) {
            messageApi.error(incorrectKeyMessage, messageTimeout);
          }
          return res.json();
        })
        .then((data) => {
          const link = document.createElement('a');
          link.href = `data:application/pdf;base64,${data.base64}`;
          link.setAttribute('download', `faktúry.pdf`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setLoading(false);
        })
        .catch((error) => {
          messageApi.error(unableToChangeTicketStateMessage, messageTimeout);
          setLoading(false);
        });

    }
  }

  useEffect(() => {
    getConfig().then((data) => {
      setConfig(data);
    })
  }, []);

  useEffect(() => {
    const key = Cookies.get(cookieApiAdminKey);
    setXApiAdminKey(key);
  }, []);

  return (
    <div className='admin'>
      {contextHolder}
      {xApiAdminKey == null
        ?
        <Space.Compact>
          <Input.Password placeholder="heslo" onChange={(e) => setInputValue(e.target.value)} onPressEnter={() => storeXApiAdminKey(inputValue)} />
          <Button type="primary" onClick={() => storeXApiAdminKey(inputValue)}>Vstup</Button>
        </Space.Compact>
        : <>
          <Button
            type="primary"
            icon={<CloseOutlined />}
            onClick={() => removeCookie()}
            className='admin-close-button'
          />

          <Space.Compact >
            <Input placeholder='Event ID' onChange={(e) => setEventId(e.target.value)} />
            <Button
              type="primary"
              disabled={loading}
              icon={loading ? <LoadingOutlined /> : <DownloadOutlined />}
              onClick={() => downloadInvoices()}
            >
              {loading ? "Generujem ..." : "Stiahnuť Faktúry"}
            </Button>
          </Space.Compact>
        </>
      }
    </div >
  );
};


export default Admin;
