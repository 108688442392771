import './Finished.css';

const Finish = () => {

  return (
    <div className='event-finished'>
      <span>
        Predaj vstupeniek bol ukončený
      </span>
    </div>
  )
}

export default Finish;