import React from 'react';
import Countdown from 'react-countdown';
import { CounterProps } from './CounterModel';
import './Counter.css';

const Counter = () => {

  const counter = (counter: CounterProps) => {
    if (counter.completed) {
      return <div />;
    } else {
      return <span className='event-counter'>{counter.days} dní {counter.hours} hodín {counter.minutes} minút {counter.seconds} sekúnd</span>;
    }
  };

  return (
    <Countdown date={'2024-09-28T09:30:00'} renderer={counter} />
  );
};

export default Counter;