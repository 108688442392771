import React from 'react';

const Terms = () => {

  return (
    <div>
      <p>Prevádzkovateľom portálu vstup.me (ďalej len Prevádzkovateľ) je Gaskor s.r.o, Gaštanová 92, 076 15, Hrčeľ, IČO: 50152165, DIČ: 2120190259.</p>
      <p>Používateľom (ďalej len Používateľ) je akákoľvek fyzická alebo právnická osoba, ktorá má záujem o sprostredkovateľské služby portálu vstup.me (ďalej len Služba).</p>
      <p>Pod sprostredkovateľskými službami portálu vstup.me sa rozumejú služby poskytované cez predajný systém vstup.me (ďalej len Systém), prostredníctvom ktorého môžu vinári, vinárske spolky a iné spoločnosti zaoberajúce sa predajom a propagáciou vína (ďalej len Poskytovateľ) ponúkať rezervácie na svoje vinárske podujatia (ďalej len Podujatia), a ktorý umožňuje Používateľovi rezervovať si Vstupenku/Vstupenky na Podujatia Poskytovateľa a rezervovanú(é) Vstupenku/Vstupenky si uplatniť u Poskytovateľa.</p>
      <h4>Služba</h4>
      <p>vstup.me je poskytovateľom služieb tak, ako sú definované v týchto podmienkach, čo zahŕňa najmä sprostredovateľské služby spojené s prevádzkovaním online rezervačného systému pre Podujatia Poskytovateľov a prevádzku Systému.</p>
      <p>S ohľadom na Podujatia, rezervácie Vstupeniek a úhradu zálohy za vstupenky na účet Prevádzkovateľa (prechodne), pôsobí vstup.me iba ako sprostredkovateľ medzi Používateľom - nákupcom Vstupenky/Vstupeniek a príslušným Poskytovateľom – predajcom Vstupenky/Vstupeniek a organizátorom Podujatia. Prostredníctvom rezervačného systému vstup.me je Používateľ schopný vykonať rezerváciu Vstupenky/Vstupeniek na Podujatie a obdržať rezervovanú Vstupenku/Vstupenky, ktorú(é) Poskytovateľ považuje za doklad o rezervácii Vstupenky/Vstupeniek a o úhrade zálohy za Vstupenku/Vstupenky Používateľom.</p>
      <p>vstup.me neprijíma žiadne finančné prostriedky, či iné platby od Používateľov vo svojom mene za poskytované Služby. Platby od Používateľov sú podľa tejto dohody zhromažďované (ako Záloha) v mene Poskytovateľa ako záloha na Vstupenku/Vstupenky, vstup.me si ich ponecháva len dočasne (ako Zálohu) a potom ich pošle Poskytovateľovi v súlade s príslušnou samostatnou zmluvou medzi Prevádzkovateľom a Poskytovateľom.</p>
      <h4>Proces rezervácie</h4>
      <p>Podujatia uverejnené v Systéme sú vytvorené na základe informácií Poskytovateľov, ktorí sú výhradne zodpovední za organizáciu týchto podujatí a za to, aby boli všetky dôležité informácie zverejnené v Systéme o Podujatí úplne pravdivé, presné a za žiadnych okolností klamlivé.</p>
      <p>Pre rezerváciu Vstupenky/Vstupeniek na príslušnú akciu, musí Používateľ použiť príslušné tlačidlo pri Podujatí zverejnenom v Systéme, zadať počet Vstupeniek a potvrdiť ich rezerváciu. Týmto potvrdením Používateľ tiež potvrdzuje, že si prečítal celý popis vzťahujúci sa na dané Podujatie a rozumie mu.</p>
      <p>Po potvrdení rezervácie Vstupenky/Vstupeniek v Systéme je Používateľ presmerovaný na platobnú bránu, kde sú zhrnuté detaily rezervácie (názov Podujatia, celková cena rezervácie, cena za zálohu, cena, ktorú treba doplatiť na mieste (priamo Poskytovateľovi) a možné spôsoby platby. Používateľ si vyberie vhodný spôsob platby a postup platby za Zálohu pre vstup.me vykoná na základe inštrukcií uvedených v Systéme.</p>
      <p>Po oznámení o prijatí platby za Zálohu od Používateľa, zašle vstup.me Používateľovi (emailom) potvrdenie o rezervácii Vstupenky/Vstupeniek s informáciou, že rezervácia bola dokončená. Kódy pre uplatnenie lístkov u Poskytovateľa sú generované Systémom vstup.me hneď po rezervácii Používateľom a sú zaslané na používateľský email.</p>
      <p>Majiteľ Vstupenky/Vstupeniek (jedna osoba) sa po preukázaní Vstupenky/Vstupeniek (kódu) môže zúčastniť príslušného Podujatia. Poskytovateľ, ako organizátor Podujatia, musí Vstupenku/Vstupenky vygenerovanú(é) Systémom vstup.me uznať a prijať ako exkluzívnu platnú Vstupenku na Podujatie. Po overení kódu Poskytovateľom na Podujatí, sa Vstupenka/Vstupenky nedá/nedajú opätovne použiť.</p>
      <h4>Dohoda o podujatí</h4>
      <p>Vykonaním rezervácie v Systéme je vytvorená priama Dohoda (a tým právny vzťah) výhradne medzi Poskytovateľom a Používateľom (ďalej len Dohoda o Akcii), vo vzťahu k Podujatiu konkrétneho Poskytovateľa, na ktorú bola rezervácia vykonaná. Poskytovateľ je povinný prijať Používateľa za zmluvnú stranu a vyhovieť rezervácii v súlade s popisom Podujatia (najmä s ohľadom na cenu Vstupenky/Vstupeniek) zverejneným v Systéme v čase, keď bola rezervácia vykonaná a následne potvrdená Používateľovi od vstup.me.</p>
      <p>Iné poplatky, ako tie stanovené v potvrdenej rezervácii, nesmú byť Používateľovi Poskytovateľom účtované (napr. administratívny poplatok / alebo poplatok za použitie akéhokoľvek spôsobu platby (napr. poplatok kreditných kariet)).</p>
      <p>V prípade, že sa Používateľ na Podujatí nezúčastní, Používateľ nebude mať nárok na vrátenie peňazí, ani iný typ odškodnenia od vstup.me.</p>
      <p>S ohľadom na konkrétnu Dohodu o podujatí je Poskytovateľ povinný zorganizovať a vykonať Podujatie a splniť všetky svoje povinnosti voči Používateľovi vyplývajúce z daného Podujatia.</p>
      <p>vstup.me nenesie žiadnu zodpovednosť za riadne a včasné plnenie Dohody o podujatí zo strany Poskytovateľa, ktorá zahŕňa predovšetkým riadnu organizáciu Podujatia Poskytovateľom. Sťažnosti alebo požiadavky namierené na ponúkané služby alebo výrobky, vykonávané alebo poskytnuté od určitého Poskytovateľa, alebo špecifické žiadosti od Používateľa, je povinný riešiť daný Poskytovateľ, bez zapojenia vstup.me. vstup.me nenesie žiadnu zodpovednosť v súvislosti s požiadavkami zo strany Používateľa.</p>
      <h4>Zrušenie rezervácie</h4>
      <p>Používateľ berie na vedomie, že zrušenie Rezervácie / Podujatia podlieha zásade no-storno, tzn. že akékoľvek zrušenie je možné len za podmienok výslovne ustanovených v tomto dokumente.</p>
      <p>Akonáhle Používateľ v Systéme vykoná platnú rezerváciu, nie je možné ju z akýchkoľvek dôvodov, za ktoré je sám zodpovedný, zrušiť. Zároveň Používateľ nie je oprávnený odstúpiť od Dohody o podujatí s daným Poskytovateľom Podujatia alebo ju ukončiť. Používateľ nemá nárok na vrátenie akejkoľvek (nevratnej) predplatenej sumy od vstup.me alebo od Poskytovateľa, pokiaľ s tým Poskytovateľ nesúhlasí alebo iným spôsobom to neumožní v rámci vlastných predpredajových a storno podmienok.</p>
    </div>
  );
}

export default Terms;