import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { PaymentFormPros } from './PaymentFormModel';
import { ConfigProps } from '../../Config/ConfigModel';
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { StripePaymentElementOptions } from "@stripe/stripe-js";
import { Button, Tooltip, message } from "antd";
import { NoticeType } from 'antd/es/message/interface';
import { getConfig } from '../../Config/config';
import './PaymentForm.css'

const PaymentForm: React.FC<PaymentFormPros> = ({ clientSecret, orderId, amount, currencySymbol, onPrev }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const byPass = queryParams.get('x');

  const stripe = useStripe();
  const elements = useElements();
  const [messageApi, contextHolder] = message.useMessage();
  const [config, setConfig] = useState<ConfigProps>();
  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentEnabled, setIsPaymentEnabled] = useState(false);

  const setMessage = useCallback((message: string, type: NoticeType) => {
    messageApi.open({
      type: type,
      content: message,
    });
  }, [messageApi]);

  useEffect(() => {
    setIsPaymentEnabled((config && config.paymentEnabled) || byPass === 'true')
  }, [config, byPass]);

  useEffect(() => {
    getConfig().then((data) => {
      setConfig(data);
    })
  }, []);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          // setMessage("Payment succeeded!");
          break;
        case "processing":
          break;
        case "requires_payment_method":
          break;
        default:
          break;
      }
    });
  }, [stripe, clientSecret, setMessage]);

  const formatAmount = (amount: number) => {
    const amountString = amount.toString();
    let position = amountString.length - 2;
    return `${amountString.slice(0, position)},${amountString.slice(position)}`;
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${config.baseUrl}/payment-status?id=${orderId}`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message, "error");
    } else {
      setMessage("Platba zlyhala.", "error");
    }
    setIsLoading(false);
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: 'tabs'
  };

  const paymentButton = (
    <Button type="primary" htmlType="submit" disabled={isLoading || !stripe || !elements || !isPaymentEnabled} id="submit">
      <span id="button-text">
        {isLoading ? <div className="spinner" id="spinner"></div> : `Zaplatiť ${formatAmount(amount)} ${currencySymbol}`}
      </span>
    </Button>
  );

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {contextHolder}
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <div className='payment-buttons'>
        <Button type="dashed" onClick={onPrev} tabIndex={-1} disabled={isLoading || !stripe || !elements}>
          Späť
        </Button>
        {isPaymentEnabled
          ? paymentButton
          : <Tooltip title="Predaj vstupeniek ešte nezačal" color="orange">
            {paymentButton}
          </Tooltip>

        }
      </div>
    </form>
  );
};

export default PaymentForm;