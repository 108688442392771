import React, { MouseEvent, useEffect, useState } from 'react'
import {
  Button,
  Form,
  Input,
  InputNumber,
  Row,
  Col,
  Checkbox,
  Modal,
  Radio,
} from 'antd'
import { getConfig } from '../../Config/config';
import { ConfigProps } from '../../Config/ConfigModel';
import { UserFormProps, EventTypeLimit } from './UserFormModel';
import Privacy from '../../Footer/Privacy/Privacy';
import Terms from '../../Footer/Terms/Terms';
import './UserForm.css';


const ticketTypeTooltip = "Doprava je realizovaná špeciálnym festivalovým vlakom z Košíc do Čerhova a späť."

const validateMessages = {
  required: 'Je potrebné vyplniť ${label}',
  types: {
    email: '${label} nie je správnom formáte',
    number: 'Nie je číslo',
  },
  number: {
    range: 'Počet lístkov musí být medzi ${min} a ${max}',
  },
};

const checkboxValidationMessages = {
  business: "Je nutné vyjadriť súhlas s obchodnými podmienkami.",
  privacy: "Je nutné vyjadriť súhlas so spracovaním osobných údajov."
}

const privacyTermsTitle = "Ocharana osobných údajov";
const businessTermsTitle = "Všeobecné obchodné podmienky";


const spanOneCol = {
  span: 24,
  sm: 24
}

const spanTwoCol = {
  span: 24,
  sm: 12
}

const UserForm: React.FC<UserFormProps> = ({ onNext, user }) => {

  const [businessTermsModalVisible, setBusinessTermsModalVisible] = React.useState(false);
  const [privacyTermsModalVisible, setPrivacyTermsModalVisible] = React.useState(false);
  const [config, setConfig] = useState<ConfigProps>();
  const [limits, setLimits] = useState<EventTypeLimit>({});
  const [ticketMax, setTicketMax] = useState(20);

  useEffect(() => {
    getConfig().then((data) => {
      setConfig(data);
    })
  }, []);

  useEffect(() => {
    if (config && config.baseUrl) {
      fetch(`${config.baseUrl}/api/ticket?type=init&eventId=1`)
        .then((res) => res.json())
        .then((data) => {
          setLimits(data);
        });
    }
  }, [config]);

  useEffect(() => {
    if (limits[user.type] != null) {
      if (limits[user.type] > 0) {
        setTicketMax(limits[user.type]);
      } else {
        setTicketMax(0);
      }
    } else {
      setTicketMax(20);
    }
  }, [limits, user.type]);

  const handleTicketCount = (value: number) => {
    user.setTicketCount(value);
  }

  const openPrivacyTermsModal = (event: MouseEvent) => {
    setPrivacyTermsModalVisible(true);
    event.preventDefault();
  }

  const openBusinessTermsModal = (event: MouseEvent) => {
    setBusinessTermsModalVisible(true);
    event.preventDefault();
  }

  const isTicketTypeDisabled = (type: string): boolean => {
    if (limits[type] != null && limits[type] <= 0) {
      return true;
    }
    return false;
  }

  const validateEmail = (_: any, value: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!value) {
      return Promise.reject(new Error('Je potrebné vyplniť ${label}'));
    } else if (!emailRegex.test(value)) {
      return Promise.reject(new Error('${label} nie je správnom formáte. '));
    }
    return Promise.resolve();
  };

  return <div>
    <Form
      onFinish={onNext}
      validateMessages={validateMessages}
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 15 }}
    >
      <Row>
        <Col {...spanTwoCol}>
          <Form.Item name={['name']} label="Meno" rules={[{ required: true }]} initialValue={user.name}>
            <Input onChange={(e) => user.setName(e.target.value)} />
          </Form.Item>
        </Col>
        <Col {...spanTwoCol}>
          <Form.Item name={['surname']} label="Priezvisko" rules={[{ required: true }]} initialValue={user.surname}>
            <Input onChange={(e) => user.setSurname(e.target.value)} />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col {...spanTwoCol}>
          <Form.Item name={['street']} label="Ulica a číslo" rules={[{ required: true }]} initialValue={user.street}>
            <Input onChange={(e) => user.setStreet(e.target.value)} />
          </Form.Item>
        </Col>
        <Col {...spanTwoCol}>
          <Form.Item name={['city']} label="Mesto" rules={[{ required: true }]} initialValue={user.city}>
            <Input onChange={(e) => user.setCity(e.target.value)} />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col {...spanTwoCol}>
          <Form.Item name={['postal']} label="PSČ" rules={[{ required: true }]} initialValue={user.postal}>
            <Input onChange={(e) => user.setPostal(e.target.value)} />
          </Form.Item>
        </Col>
        <Col {...spanTwoCol}>
          <Form.Item name={['email']} label="Email" rules={[{ validator: validateEmail }]} initialValue={user.email}>
            <Input onChange={(e) => user.setEmail(e.target.value)} />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col {...spanTwoCol}>
          <Form.Item name={['ticket']} label="Počet Lístkov" rules={[{ type: 'number', min: 1, max: ticketMax, required: true }]} initialValue={user.ticketCount}>
            <InputNumber className='ticket-count' onChange={handleTicketCount} />
          </Form.Item>
        </Col>
      </Row>

      <Row>

        <Col {...spanOneCol}>
          <Form.Item
            wrapperCol={{ span: 24 }}
            name="ticket-type"
            label="Typ vstupenky"
            rules={[{ required: true, message: 'Prosim zvoľte typ vstupenky' }]}
            tooltip={ticketTypeTooltip}
          >
            <Radio.Group onChange={(e) => { user.setType(e.target.value) }} buttonStyle="solid">
              <Radio.Button value="type1" disabled={isTicketTypeDisabled('type1')}>25€ bez dopravy</Radio.Button>
              <Radio.Button value="type2" disabled={isTicketTypeDisabled('type2')}>35€ s dopravou</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>

      </Row>

      <Row>
        <Col {...spanOneCol}>
          <Form.Item
            wrapperCol={{ span: 24 }}
            name={['business-terms']}
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value === true ? Promise.resolve() : Promise.reject(new Error(checkboxValidationMessages.business)),
              },
            ]}
          >
            <Checkbox>Súhlasím s <span className='event-form-modal-link' onClick={openBusinessTermsModal}>obchodnými podmienkami</span> predaja vstupeniek.</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Modal
        title={businessTermsTitle}
        centered
        open={businessTermsModalVisible}
        onOk={() => setBusinessTermsModalVisible(false)}
        onCancel={() => setBusinessTermsModalVisible(false)}
        closable={true}
        footer={
          <Button type="primary" onClick={() => setBusinessTermsModalVisible(false)}>
            OK
          </Button>
        }
      >
        <Terms />
      </Modal>

      <Row>
        <Col {...spanOneCol}>
          <Form.Item
            wrapperCol={{ span: 24 }}
            name={['privacy-terms']}
            valuePropName="checked"
            rules={[
              {
                validator: (e, value) => {
                  console.log(e);
                  return value === true ? Promise.resolve() : Promise.reject(new Error(checkboxValidationMessages.privacy));
                }
              },
            ]}
          >
            <Checkbox>Súhlasím so spracovaním <span className='event-form-modal-link' onClick={openPrivacyTermsModal}>osobných údajov</span>.</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Modal
        title={privacyTermsTitle}
        centered
        open={privacyTermsModalVisible}
        onOk={() => setPrivacyTermsModalVisible(false)}
        onCancel={() => setPrivacyTermsModalVisible(false)}
        closable={true}
        footer={
          <Button type="primary" onClick={() => setPrivacyTermsModalVisible(false)}>
            OK
          </Button>
        }
      >
        <Privacy />
      </Modal>

      <Row justify={'end'}>
        <Col span={5} className='event-form-next'>
          <Button type="primary" htmlType="submit">
            Pokračovať
          </Button>
        </Col>
      </Row>

    </Form>
  </div>;
};

export default UserForm;