import React, { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { getConfig } from '../Config/config';
import { ConfigProps } from '../Config/ConfigModel';
import Details from './Details/Details';
import PaymentSteps from '../Payment/PaymentSteps/PaymentSteps';
import Footer from '../Footer/Footer';
import Counter from './Counter/Counter';
import Sponsors from './Sponsors/Sponsors';
import Program from './Program/Program';
import Finished from './Finished/Finished';
import './Event.css';

const Event = () => {
  const [config, setConfig] = useState<ConfigProps>();

  useEffect(() => {
    getConfig().then((data) => {
      setConfig(data);
    })
  }, []);

  return (
    <div className='event'>
      <section>
        <Details />
      </section>
      <section>
        <Counter />
      </section>
      <section className='event-payment-steps'>
        {config
          ? config.isFinished
            ? <Finished />
            : <PaymentSteps />
          : <LoadingOutlined />
        }
      </section>
      <section>
        <Sponsors />
      </section>
      <section className='event-program'>
        <Program />
      </section>
      <footer className='event-footer'>
        <Footer />
      </footer>
    </div>
  )
}

export default Event;