import React, { useState } from 'react';
import { Steps } from 'antd';
import { SmileOutlined, SolutionOutlined, EuroCircleOutlined } from '@ant-design/icons';
import UserForm from '../../Event/UserForm/UserForm';
import Payment from '../Payment';
import { UserProps } from '../../model/UserModel';
import './PaymentSteps.css';

const PaymentSteps = () => {
  const [current, setCurrent] = useState(0);
  const [name, setName] = useState<string>();
  const [surname, setSurname] = useState<string>();
  const [street, setStreet] = useState<string>();
  const [city, setCity] = useState<string>();
  const [postal, setPostal] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [ticketCount, setTicketCount] = useState(1);
  const [type, setType] = useState<string>();

  const user: UserProps = { name, surname, street, city, postal, email, ticketCount, type, setName, setSurname, setStreet, setCity, setPostal, setEmail, setTicketCount, setType };

  const next = () => {
    setCurrent(current + 1);
  }

  const prev = () => {
    setCurrent(current - 1);
  }

  const steps = [
    {
      title: 'Údaje',
      content: <UserForm onNext={next} user={user} />,
      icon: <SolutionOutlined />,
    },
    {
      title: 'Platba',
      content: <Payment onPrev={prev} user={user} />,
      icon: <EuroCircleOutlined />,
    },
    {
      title: 'Potvrdenie',
      content: 'Potvrdenie',
      icon: <SmileOutlined />,
    },
  ]

  const items = steps.map((item) => ({ key: item.title, title: item.title, icon: item.icon }))

  return (
    <>
      <Steps current={current} items={items} className='payment-steps-header'/>
      <div>{steps[current].content}</div>
    </>
  )

};

export default PaymentSteps;