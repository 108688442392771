import React from 'react';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Event from './Event/Event';
import TicketVerify from './TicketVerify/TicketVerify';
import PaymentStatus from './Payment/PaymentStatus/PaymentStatus';
import Admin from './Admin/Admin';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Event />,
  },
  {
    path: "/na",
    element: <Event />,
  },
  {
    path: "/do",
    element: <Event />,
  },
  {
    path: "/tokajske-vinobranie",
    element: <Event />,
  },
  {
    path: "*",
    element: <Event />,
  },
  {
    path: "/ticket/verify",
    element: <TicketVerify />,
  },
  {
    path: "/payment-status",
    element: <PaymentStatus />,
  },
  {
    path: "/admin",
    element: <Admin />,
  },
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
