import React, { useEffect, useState } from 'react';
import { loadStripe, StripeElementsOptions, Appearance } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { PaymentProps } from './PaymentModel';
import { ConfigProps } from '../Config/ConfigModel';
import { getConfig } from '../Config/config';
import { message } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import './Payment.css';

import PaymentForm from './PaymentForm/PaymentForm';

let stripePromise: Promise<any>;

const Payment: React.FC<PaymentProps> = ({ onPrev, user: { name, surname, street, city, postal, email, ticketCount, type } }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const [clientSecret, setClientSecret] = useState("");
  const [orderId, setOrderId] = useState("");
  const [amount, setAmount] = useState<number>();
  const [currencySymbol, setCurrencySymbol] = useState("€");
  const [config, setConfig] = useState<ConfigProps>();

  useEffect(() => {
    getConfig().then((data) => {
      setConfig(data);
    })
  }, []);

  useEffect(() => {
    if (config && config.stripePublic) {
      stripePromise = loadStripe(config.stripePublic);
    }
  }, [config]);

  useEffect(() => {
    if (config && config.baseUrl) {
      fetch(`${config.baseUrl}/api/ticket?type=payment-init`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, surname, street, city, postal, ticketCount, email, type }),
      })
        .then((res) => res.json())
        .then((data) => {
          setClientSecret(data.clientSecret)
          setOrderId(data.orderId);
          setAmount(data.amount);
          setCurrencySymbol(data.currencySymbol);
        })
        .catch(() => {
          messageApi.error("Platby sú momentálne nedostupné. Skúste to neskôr.", 60);
        });
    }

  }, [config, messageApi, name, surname, street, city, postal, email, ticketCount, type]);

  const appearance: Appearance = {
    theme: 'stripe',
  };
  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
  };

  return (
    <div>
      {contextHolder}
      {clientSecret && stripePromise
        ? (
          <Elements options={options} stripe={stripePromise}>
            <PaymentForm onPrev={onPrev} clientSecret={clientSecret} orderId={orderId} amount={amount} currencySymbol={currencySymbol} />
          </Elements>
        )
        : (
          <Spin className='payment-loading' indicator={<LoadingOutlined spin />} size="large" />
        )
      }
    </div>
  );
};

export default Payment;