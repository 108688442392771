import React from 'react';

const Cookies = () => {

  return (
    <div>
      <h4>Podmienky používania - Cookies</h4>
      <p>Služba vstup.me využíva súbory cookies a pixely na zbieranie informácií a zlepšovanie našich služieb. Tieto zásady obsahujú podrobnosti o tom, ako portál vstup.me používa súbory cookies. Ak navštívite naše webové stránky a v prehliadači je povolené prijímanie súborov cookies, považujeme to za prijatie našich podmienok používania súborov cookies.</p>
      <p>Cookies sú miniatúrne súbory, ktoré server uloží do zariadenia návštevníka internetových stránok pri vstupe na internetové stránky využívajúce túto technológiu a/alebo pri ich používaní. Súbory cookies, ktoré používajú stránky Služby, nepoškodzujú zariadenia ich návštevníkov, neumožňujú prístup na pevné disky ani iné pamäťové zariadenia týchto zariadení ani nijakým spôsobom neumožňujú ovládanie týchto zariadení. Služba vstup.me uskutočňuje zber anonymných dát. Pixely sú malé obrázky alebo "kúsky kódu", nachádzajúce sa na internetovej stránke alebo v e-maile, ktoré zbierajú informácie o vašom prehliadači alebo mobilnom zariadení a niektoré z nich môžu ukladať aj cookies.</p>
      <p>Súbory cookies do vášho počítača posielajú a ukladajú webové stránky, ktoré navštívite. Súbory cookie sa ukladajú do priečinka pre súbory vášho prehliadača. Pri ďalšej návšteve stránky webový prehliadač znovu načíta súbory cookie a tieto informácie odošle späť webovej stránke alebo prvku, ktorý pôvodne súbor cookie vytvoril. Ak si chcete prečítať ďalšie informácie o týchto technológiách a o tom, ako fungujú, pozrite si napríklad allaboutcookies.org.</p>
      <p>Súbory cookies používame s cieľom optimálne vytvárať a neustále skvalitňovať naše služby, prispôsobiť ich vašim potrebám a zlepšovať ich štruktúru a obsah.</p>
      <h4>Aké typy súborov cookie používame?</h4>
      <h5>Základné súbory cookie</h5>
      <p>Tieto súbory cookie tvoria základ potrebný na prevádzku našich webových stránok a sú kľúčom k poskytovaniu ich plynulých funkcií. Základnými cookies sú napr. zapamätanie prihlásenia, nastavenie jazyka a ďalšie. Pokiaľ tieto cookies zakážete, nebudeme môcť zaručiť bezchybný chod stránok.</p>
      <h5>Ako môžete súbory cookie kontrolovať alebo vymazať?</h5>
      <p>Väčšina internetových prehliadačov je pôvodne nastavená na automatické akceptovanie cookies. Toto nastavenie môžete zmeniť zablokovaním cookies alebo upozornením v prípade, že sa majú cookies poslať do vášho zariadenia. Existuje veľa spôsobov na riadenie cookies. Preštudujte si pokyny vášho prehliadača alebo pomocníka, aby ste sa dozvedeli viac o tom, ako prispôsobiť alebo upraviť nastavenia vášho prehliadača. Ak zablokujete cookies, ktoré používame, môže to ovplyvniť funkčnosť našich stránok, napríklad nebudete môcť navštíviť niektoré stránky alebo nebudete dostávať počas návštevy stránok personalizované informácie.</p>
    </div>
  );
}

export default Cookies;