import React from 'react';
import './Details.css';

const Details = () => {
  return (
    <div className='event-details'>
      <img src="tokajske-vinobranie.png" alt="Logo Tokajské vinobranie" className='event-logo' />
      <div>
        <h1 className='event-title'>27-28.September 2024</h1>
      </div>
    </div>
  );
};

export default Details;