import React, { useState } from 'react';
import { Button, Modal, Typography } from 'antd';
import Privacy from './Privacy/Privacy';
import Terms from './Terms/Terms';
import Cookies from './Cookies/Cookies';
import './Footer.css';

const { Text } = Typography;

const privacyTitle = "Ocharana osobných údajov";
const termsTitle = "Všeobecné obchodné podmienky";
const cookiesTitle = "Súbory cookies";


const Footer = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContentType, setModalContentType] = useState('privacy');
  const [modalTitle, setModalTitle] = useState('');

  const openModalWithContent = (contentType: string, title: string) => {
    setModalContentType(contentType);
    setModalTitle(title);
    setModalOpen(true);
  }

  const getModalContent = () => {
    switch (modalContentType) {
      case 'privacy':
        return <Privacy />;
      case 'terms':
        return <Terms />;
      case 'cookies':
        return <Cookies />;
      default:
        return <Privacy />;
    }
  }

  return (
    <div className='footer-content'>
      {/* <div className='footer-contact footer-items'>
        <span>email@email.sk</span>
        <span>+421 111 000 111</span>
      </div> */}
      <div className='footer-info footer-items'>
        <Text underline onClick={() => openModalWithContent('privacy', privacyTitle)}>{privacyTitle}</Text>
        <Text underline onClick={() => openModalWithContent('terms', termsTitle)}>{termsTitle}</Text>
        <Text underline onClick={() => openModalWithContent('cookies', cookiesTitle)}>{cookiesTitle}</Text>
      </div>
      <Modal
        title={modalTitle}
        centered
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        closable={true}
        footer={
          <Button type="primary" onClick={() => setModalOpen(false)}>
            OK
          </Button>
        }
      >
        {getModalContent()}
      </Modal>
    </div>
  );
}

export default Footer;