import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined, LoadingOutlined, CloseOutlined, ReloadOutlined } from '@ant-design/icons';
import {
  message,
  Input,
  Button,
  Space,
} from "antd";
import Cookies from 'js-cookie'

import { ConfigProps } from '../Config/ConfigModel';
import { UnusedProps } from './TicketVerifyModel';
import { getConfig } from '../Config/config';

import './TicketVerify.css';

const validMessage = 'Lístok je platný';
const invalidMessage = 'Lístok je NEPLATNÝ';
const usedMessage = 'Lístok už bol použitý';
const loadingMessage = 'Načítam...';
const unableToVerifyMessage = 'Nepodarilo sa načítať stav lístka. Lístok može byť platný alebo neplatný. Skúste prosím znovu. ';
const unableToChangeTicketStateMessage = 'Nepodarilo upraviť stav lístka. Skúste prosím znovu. ';
const unableToLoadUnusedTicketsMessage = 'Nepodarilo sa načítať počet nepoužitých vstupeniek. Skúste prosím znovu. ';
const incorrectKeyMessage = 'Heslo nie je správne.';
const messageTimeout = 15;

const cookieApiUserKey = 'vstup-me-api-user-key';
const cookieExpirationInDays = 5;


const TicketVerify = () => {
  const [config, setConfig] = useState<ConfigProps>();
  const [status, setStatus] = useState<'valid' | 'invalid' | 'used' | 'loading'>('loading');
  const [type, setType] = useState<'type1' | 'type2'>();
  const [xApiUserKey, setXApiUserKey] = useState<string>();
  const [inputValue, setInputValue] = useState<string>();
  const [unused, setUnused] = useState<UnusedProps[]>();

  const [messageApi, contextHolder] = message.useMessage();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const eventId = queryParams.get('eventId');
  const ticketId = queryParams.get('ticketId');

  const valid = <CheckCircleOutlined />;
  const invalid = <CloseCircleOutlined />;
  const used = <ExclamationCircleOutlined />;
  const loading = <LoadingOutlined />;

  const getStatusIcon = () => {
    switch (status) {
      case 'valid':
        return valid;
      case 'invalid':
        return invalid;
      case 'used':
        return used;
      default:
        return loading;
    }
  }

  const getStatusMessage = () => {
    switch (status) {
      case 'valid':
        return validMessage;
      case 'invalid':
        return invalidMessage;
      case 'used':
        return usedMessage;
      default:
        return loadingMessage;
    }
  }

  const getStatusTypeMessage = () => {
    if (status === 'valid') {
      return getTypeMessage(type);
    }
    return '';
  }

  const getTypeMessage = (type: string) => {
    if (type === 'type1') {
      return 'Bez dopravy \uD83D\uDEB6\uD83C\uDFFB';
    } else if (type === 'type2') {
      return 'S dopravou \uD83D\uDE82';
    }
  }

  const storeXApiUserKey = (key: string) => {
    Cookies.set(cookieApiUserKey, key, { expires: cookieExpirationInDays });
    setXApiUserKey(key);
  }

  const removeCookie = () => {
    Cookies.remove(cookieApiUserKey);
    setXApiUserKey(null);
  }

  const updateTicketStatus = () => {
    if (config && config.baseUrl && eventId && ticketId && xApiUserKey) {
      if (status === 'valid' || status === 'used') {
        const newStatus = status === 'valid' ? 'used' : 'valid';
        fetch(`${config.baseUrl}/api/ticket?type=verification`,
          {
            method: 'POST',
            headers: {
              'x-api-user-key': xApiUserKey,
            },
            body: JSON.stringify({ eventId, ticketId, status: newStatus }),
          }
        )
          .then((res) => {
            if (res.status === 401) {
              messageApi.error(incorrectKeyMessage, messageTimeout);
            }
            return res.json();
          })
          .then((data) => {
            setStatus(data.status);
          })
          .catch((error) => {
            messageApi.error(unableToChangeTicketStateMessage, messageTimeout);
            setStatus('loading');
          });
      }
    }
  }

  const reloadUnused = useCallback(() => {
    if (config && config.baseUrl && eventId && xApiUserKey) {
      fetch(`${config.baseUrl}/api/ticket?type=verification-unused&eventId=${eventId}`,
        {
          method: 'GET',
          headers: {
            'x-api-user-key': xApiUserKey,
          },
        }
      )
        .then((res) => {
          if (res.status === 401) {
            messageApi.error(incorrectKeyMessage, messageTimeout);
          }
          return res.json();
        })
        .then((data) => {
          setUnused(data);
        })
        .catch((error) => {
          messageApi.error(unableToLoadUnusedTicketsMessage, messageTimeout);
        });
    }
  }, [config, messageApi, eventId, xApiUserKey]);

  useEffect(() => {
    getConfig().then((data) => {
      setConfig(data);
    })
  }, []);

  useEffect(() => {
    const key = Cookies.get(cookieApiUserKey);
    setXApiUserKey(key);
  }, []);

  useEffect(() => {
    if (config && config.baseUrl && eventId && ticketId && xApiUserKey) {
      fetch(`${config.baseUrl}/api/ticket?type=verification&eventId=${eventId}&ticketId=${ticketId}`,
        {
          method: 'GET',
          headers: {
            'x-api-user-key': xApiUserKey,
          },
        }
      )
        .then((res) => {
          if (res.status === 401) {
            messageApi.error(incorrectKeyMessage, messageTimeout);
          }
          return res.json();
        })
        .then((data) => {
          if (data.status == null) {
            messageApi.error(unableToVerifyMessage, messageTimeout);
          }
          setStatus(data.status);
          setType(data.type);
        })
        .catch((error) => {
          messageApi.error(unableToVerifyMessage, messageTimeout);
          setStatus('loading');
        });
    }
  }, [config, messageApi, eventId, ticketId, xApiUserKey, reloadUnused]);

  return (
    <div className='ticket-verify'>
      {contextHolder}
      {xApiUserKey == null
        ?
        <Space.Compact>
          <Input.Password placeholder="heslo" onChange={(e) => setInputValue(e.target.value)} onPressEnter={() => storeXApiUserKey(inputValue)} />
          <Button type="primary" onClick={() => storeXApiUserKey(inputValue)}>Vstup</Button>
        </Space.Compact>
        : <>
          <Button
            type="primary"
            icon={<CloseOutlined />}
            onClick={() => removeCookie()}
            className='ticket-verify-close-button'
          />
          <div className={ticketId != null && 'ticket-verify-unused-ticket'}>
            <div>
              <Button
                type="primary"
                icon={<ReloadOutlined />}
                onClick={() => reloadUnused()}
              >
                Nepoužité lístky
              </Button>
            </div>
            {unused?.length > 0 &&
              <>
                {unused?.map((status) => (
                  <div>
                    <span>{status.remaining}</span>
                    <span> - </span>
                    <span>{getTypeMessage(status.type)}</span>
                  </div>
                ))}
              </>
            }
          </div>
          {ticketId != null &&
            <>
              <div className={'ticket-verify-icon ticket-verify-icon-' + status}>{getStatusIcon()}</div>
              <span>{ticketId}</span>
              <span>{getStatusMessage()}</span>
              <span>{getStatusTypeMessage()}</span>
              {status === 'valid' || status === 'used'
                ? < Button
                  type="primary"
                  onClick={() => updateTicketStatus()}
                  className='ticket-verify-update-status-button'
                >
                  {status === 'valid' ? 'Použiť lístok' : 'Zrušiť použitie lístka'}
                </Button>
                : null
              }
            </>
          }
        </>
      }
    </div >
  );
};


export default TicketVerify;
