
import local from './config.json'
import { ConfigProps } from './ConfigModel';

const getConfig = async (): Promise<ConfigProps> => {
  return process.env.REACT_APP_IS_LOCAL ? Promise.resolve(local) : await loadRemoteConfig();
}

const loadRemoteConfig = async (): Promise<ConfigProps> => {
  const response = await fetch('/config.json');
  return (await response.json()) as ConfigProps;
}

export { getConfig };