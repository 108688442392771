import React from 'react';
import { useLocation } from 'react-router-dom';
import { Flex, Steps, Typography } from 'antd';
import { SolutionOutlined, EuroCircleOutlined, CheckCircleOutlined, CloseCircleOutlined, WarningOutlined } from '@ant-design/icons';
import './PaymentStatus.css';

const { Text, Title } = Typography;

const paymentMessages = {
  'succeeded': {
    'main': 'Platba prebehla úspešne.',
    'description': 'Vstupenky budú odoslané na Váš email.'
  },
  'failed': {
    'main': 'Platba zlyhala.',
    'description': ''
  },
  'canceled': {
    'main': 'Platba bola zrušená.',
    'description': ''
  }
}

type StatusType = 'succeeded' | 'failed' | 'canceled';

const PaymentStatus = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // const id = queryParams.get('id');
  const status = queryParams.get('redirect_status') as StatusType;

  const getStatusIcon = (status: StatusType) => {
    switch (status) {
      case 'succeeded':
        return <CheckCircleOutlined className={"payment-" + status} />;
      case 'failed':
        return <CloseCircleOutlined className={"payment-" + status} />;
      case 'canceled':
        return <WarningOutlined className={"payment-" + status} />;
    }
  }

  const getType = (status: StatusType) => {
    switch (status) {
      case 'succeeded':
        return 'success';
      case 'failed':
        return 'danger';
      case 'canceled':
        return 'warning';
    }
  }

  const steps = [
    {
      title: 'Údaje',
      content: <></>,
      icon: <SolutionOutlined />,
    },
    {
      title: 'Platba',
      content: <></>,
      icon: <EuroCircleOutlined />,
    },
    {
      title: 'Potvrdenie',
      content: <></>,
      icon: getStatusIcon(status),
    },
  ]

  const items = steps.map((item) => ({ key: item.title, title: item.title, icon: item.icon }))

  return (
    <div className='payment-status'>
      <div className='payment-status-icon'>
        {getStatusIcon(status)}
      </div>
      <div className='payment-status-steps'>
        <Steps current={2} items={items} className='payment-status-header' />
        <Flex vertical={true} className="payment-status-message">
          <Title level={3} type={getType(status)}>{paymentMessages[status].main}</Title>
          <Text>{paymentMessages[status].description}</Text>
        </Flex>
        <div className="payment-status-message-description"></div>
      </div>
    </div>
  )

};

export default PaymentStatus;