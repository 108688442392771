import React from 'react';
import './Sponsors.css';

const Sponsors = () => {
  return (
    <div className='event-sponsors'>
      <img src="/sponsors/mantic.png" alt="Mantič" />
      <img src="/sponsors/vinarstvo-u-kona.png" alt="Vinárstvo u Koňa" />
      <img src="/sponsors/obecna-pivnica.png" alt="Obecná pivnica" />
      <img src="/sponsors/vino-vdovjak.png" alt="Vdovjak" />
    </div>
  );
};

export default Sponsors;