import React, { useEffect, useRef } from 'react';
import './Program.css';

const Program = () => {
  const timelyRef = useRef(null);
  const id = 'timely_script';

  useEffect(() => {
    const current = timelyRef.current;
    if (current === null) {
      return;
    }
    const script = document.createElement('script');
    script.id = id;
    script.className = 'timely-script';
    script.src = 'https://events.timely.fun/embed.js';
    script.dataset.src = 'https://events.timely.fun/ttf3dnbr?notoolbar=1&nofilters=1';
    script.dataset.maxHeight = '0';

    current.appendChild(script);

    return () => {
      const timelyScript = current.getElementById(id);
      if (timelyScript) {
        current.removeChild(timelyScript);
      }
    };
  }, [timelyRef]);

  return <div ref={timelyRef} className='event-program-content'></div>;
}

export default Program;